import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161617;
`;

const Spinner = styled.div`
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #ffffff;
    width: 50px;
    height: 50px;
    animation: ${spin} 1s linear infinite;
`;

const LoadingScreen = () => {
    return (
        <LoadingContainer>
            <Spinner></Spinner>
        </LoadingContainer>
    );
}

export default LoadingScreen;