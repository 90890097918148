import React from 'react';
import useRedirect from './hooks/useRedirect';
import LoadingScreen from './componentes/LoadingScreen';

function App() {
  useRedirect();

  return (
    <div>
      <LoadingScreen/>
    </div>
  );
}

export default App;
