import { useEffect } from 'react';
import db from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const useRedirect = () => {
  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const cardID = window.location.pathname.split("/")[1];
        
        // Se não houver cardID, interrompe a execução
        if (!cardID) {
          console.error("Card ID não encontrado no URL.");
          return;
        }

        const cardRef = doc(db, "clever-cards", cardID);
        const cardSnap = await getDoc(cardRef);

        if (!cardSnap.exists()) {
          console.error("Cartão não encontrado.");
          return;
        }

        const cardData = cardSnap.data();

        // Assume valores padrão se as propriedades não existirem
        const isActive = cardData.active !== undefined ? cardData.active : true;
        const mode = cardData.mode !== undefined ? cardData.mode : 'profile';

        // Verifica se o cartão está ativo
        if (!isActive) {
          console.log("Card disabled");
          return;
        }

        // Verifica se o modo do cartão é "router" e redireciona se houver um link
        if (mode === 'router' && cardData.redirect_link && cardData.redirect_link.trim() !== '') {
          window.location.href = `${cardData.redirect_link}`;
          return;
        }

        // Se não houver ownerID, redireciona para a página de claim
        if (!cardData.ownerId) {
          window.location.href = `https://clevercard.co/claim/${cardID}`;
          return;
        }

        // Se houver ownerID, busca o nome de usuário e redireciona
        const userInfoRef = doc(db, "users-info", cardData.ownerId);
        const userInfoSnap = await getDoc(userInfoRef);
        console.log("uid", cardData.ownerId);
        if (!userInfoSnap.exists()) {
          console.error("Informações do usuário não encontradas.");
          return;
        }
        
        const userInfo = userInfoSnap.data();
        window.location.href = `https://clevercard.co/${userInfo.userInfo.username}?from_card=${cardID}`;
      } catch (error) {
        console.error("Ocorreu um erro:", error);
      }
    };

    fetchAndRedirect();
  }, []);
};

export default useRedirect;
